@font-face {
    font-family: 'BeVietnam-Bold';
    src: url('./assets/fonts/BeVietnam-Bold.ttf') format("ttf"),
        url('./assets/fonts/BeVietnam-Bold.svg') format("svg");
}

@font-face {
    font-family: 'MyriadPro';
    src: url('./assets/fonts/MyriadPro-Regular.otf');
}

.iwSUvz {
    width: 267.6px;
    max-width: 100%;
    border-radius: 0px;
}

.iJhIuy {
    font-family: 'BeVietnam-Regular';
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    color: #000000;
}

.csmOsp {
    position: fixed;
}

.Carousel-button-82 {
    top: 30px;
    color: black;
    cursor: pointer;
    margin: 0 10px;
    position: relative;
    font-size: 30px;
    transition: 200ms;
    background-color: #ffff;
    border: solid 1px #a7a8aa;
}

.vjs-control-bar .vjs-play-control {
    width: 41px;
    border-radius: 95px;
    background-color: #fe5b02;
    bottom: .1rem;
    height: 18px;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.vjs-icon-play:before {
    content: "\f101";
    font-size: 18px;
    top: -0.4rem
}


.sagicc-floating-button-bottom-right button {
    background-color: #fe5b02 !important;
}

#sagicc-chat-div {
    position: absolute !important;
    z-index: 21 !important;
}

@media only screen and (max-width: 600px) {
    .App {
        width: 89vw !important;
    }
}

.sagicc-chat-header.chat-app-bar {
    background-color: #fe5b02 !important;
}

.sagicc-chat-header.chat-app-bar h1 {
    background-color: #fe5b02 !important;
    font-family: 'BeVietnam-Bold' !important;
    line-height: 50px !important;
    height: 50px !important;
}

.sagicc-chat-initial-form.widget label,
.sagicc-chat-initial-form.widget input,
.sagicc-chat-initial-form.widget p,
.sagicc-chat-initial-form.widget div {
    font-family: 'MyriadPro' !important;
}

.sagicc-chat-initial-form.widget label {
    font-size: 18px;
    color: #fe5b02 !important;
}

.sagicc-chat-initial-form.widget input {
    font-size: 20px !important;
}

.sagicc-chat-initial-form.widget div {
    font-size: 20px !important;
}

.start-button button {
    background-color: #fe5b02 !important;
}

.start-button button span {
    font-family: 'BeVietnam-Bold' !important;
}

.sagicc-chat-footer.widget {
    display: none !important;
}

/*      Añadido         */
/* Estilo para desktop */
.imagen-desktop {
    display: block;
}

.imagen-tablet,
.imagen-mobile {
    display: none;
}

/* Estilo para tablet */
@media (max-width: 768px) {
    .imagen-desktop {
        display: none;
    }

    .imagen-tablet {
        display: block;
    }
}

/* Estilo para mobile */
@media (max-width: 576px) {
    .imagen-tablet {
        display: none;
    }

    .imagen-mobile {
        display: block;
    }
}
@media (max-width: 959.95px) {
    body .video {
        width: 100% !important;
    }
}
@media (max-width: 599.95px) {
    .makeStyles-quarterParallaxMovil-220.options .option .option__content .large >div p {
        text-align: center !important;
        font-size: 12px !important;
        line-height: 14.6px !important;
    }
    .makeStyles-quarterParallaxMovil-220.options .option .option__content .large.form >div h2 {
        font-size: 18px !important;
        line-height: 23.2px !important;
    }
    .MuiGrid-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        align-content: flex-start;
    }
    .makeStyles-quarterParallaxMovil-220.options .option:nth-child(1).show .option__content>img {
        right: 0 !important;
        height: auto !important;
    }
    /* Start que dicen de nosotros */
    /*body .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
        justify-content: space-between;
        flex-wrap: wrap;
    }*/
    body .makeStyles-containerCN-266 .swiper-slide-active .circleWhite p.description {
        height: 85px !important;
    }
    body .jss246 .swiper-slide-active .circleWhite p.description {
        height: 85px !important;
    }
    /* End que dicen de nosotros */
    body .makeStyles-containerCN-261 .swiper-container {
        padding: 0px;
    }
    .MuiBox-root.MuiBox-root-232 {
        margin-top: 0px !important;
    }
    body .new-h2 {
        font-size: 1.8rem !important;
        padding-bottom: 21%;
    }    
    body .makeStyles-quarterParallaxMovil-220.options .option .option__content .short {
        display: flex;
        align-items: flex-end;
    }
    body .meta-con-polen .makeStyles-quarterParallaxMovil-220.options {
        height: 650px;
    }
    .question.col-lg-12 p {
        font-size: 10px;
    }
    body .con-polen-logras .question h1 {
        font-size: 1.4rem;
    }
    body .inicia-tu-meta {
        padding: 6%;
        text-align: center;
    }
    .inicia-tu-meta .text-dark span {
        font-size: 2vh;
    }
    body .MuiBox-root.MuiBox-root-182.two h1.MuiTypography-root.MuiTypography-h1,
    body .MuiBox-root.MuiBox-root-182.two h3.MuiTypography-root.MuiTypography-h3 {
        font-size: 38px !important;
        line-height: 61.3px !important;
        margin-bottom: 0 !important;
    }
    body .border-botom-text-optionsL:after {
        height: 5px;
        width: 38px;
        right: 2px;
        bottom: -2px;
    }
    body .border-botom-text-optionsR:after {
        height: 5px;
        width: 38px;
        right: 2px;
        bottom: -2px;
    }
    body .meta-con-polen .makeStyles-quarterParallaxMovil-252.options {
        height: 700px !important;
    }
    body .border-botom-text-contentL:after,
    body .border-botom-text-contentR:after {
        height: 42px;
        width: 6px;
        right: -8px;
        bottom: 0px;
    }
    /*body .video {
        height: 50vh !important;
    }*/
    .short .new-h2.text-left.p-13 {
        padding-left: 0px !important;
    }
    .short .new-h2 {
        padding-right: 0px !important;
        padding-bottom: 0px !important;
    }
    .btn_help_polen_content {
        top: -18vh !important;
        right: 120px !important;
    }
    .option-nth-child2-short {
        justify-content: flex-end !important; 
    }
    body .contenedor-terminos {
        bottom: -46px !important;
    }
    .col-lg-12.que-meta-tienes-hoy.contenedor-imagen.p-6.pb-0 {
        padding: 15px 3vh;
    }
}
/*.slider-wrapper.axis-horizontal {
    height: 500px;
}*/
.video {
    width: 100% !important;
    display: block !important;
    margin: 0 auto !important;
}
ul.control-dots li.dot {
    background: #000 !important;
    opacity: 0.3 !important;
}
ul.control-dots li.dot.selected {
    background: #fe5b01 !important;
    opacity: 1 !important;
}
.position-unset {
    position: unset !important;
}
.contenedor-terminos {
    position: absolute;
    bottom: 0;
}
.contenedor-terminos .text-p-theme {
    font-size: 11px !important;
    margin: 3px;
}
/* Estilo para el contenedor */
.contenedor-imagen {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    background-image: url('assets/images/metas/fondo.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.contenedor-imagen-form {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    background-image: url('assets/images/bannerExapainPeople.png') !important;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

/* Estilo para la imagen de fondo */
.imagen-fondo {
    width: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

/* Estilo para el contenido encima de la imagen de fondo */
.contenido-imagen {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 10vh;
}

/* Estilos para las imágenes encima del fondo */
.imagen-desktop,
.imagen-tablet,
.imagen-mobile {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

/* Estilos para el texto a la izquierda y a la derecha */
.texto-izquierda,
.texto-derecha {
    position: absolute;
    text-align: center;
    width: 50%;
    top: 50%;
    transform: translateY(-50%);
}
.texto-izquierda, .texto-derecha {
    font-size: 3vh;
    color: white;
    position: absolute;
    bottom: 56vh;

}
.texto-izquierda {
    left: 0;
}
.texto-izquierda  h1{
    text-align: left;
    padding-left: 7vh;
}
.texto-derecha {
    right: 0;
}
.texto-derecha h1 {
    text-align: right;
    padding-right: 7vh;
}
.border-botom-text:after {
    content: "";
    display: block;
    height: 8px;
    border-radius: 3px;
    background: linear-gradient(90deg,transparent,#fe5b01 0,#fe5b02 100%,transparent 0);
    width: 50px;
    float: right;
    position: absolute;
    right: 0;
    bottom: -5px;
}
.position-relative {
    position: relative;
}
.border-botom-text-optionsR:after {
    content: "";
    display: block;
    height: 8px;
    border-radius: 3px;
    /*background: linear-gradient(90deg,transparent,#fe5b01 0,#fe5b02 100%,transparent 0);*/
    background: linear-gradient(0deg,#fe5b01,#fe5b01,#fe5b02 100%,transparent 0);
    width: 50px;
    float: right;
    position: absolute;
    right: 0;
    bottom: -5px;
}
.border-botom-text-contentR:after {
    content: "";
    display: block;
    height: 58px;
    border-radius: 3px;
    background: linear-gradient(90deg,transparent,#fe5b01 0,#fe5b02 100%,transparent 0);
    width: 8px;
    float: right;
    position: absolute;
    right: -9px;
    bottom: 4px;
}
.border-botom-text-contentL:after {
    content: "";
    display: block;
    height: 58px;
    border-radius: 3px;
    background: linear-gradient(90deg,transparent,#fe5b01 0,#fe5b02 100%,transparent 0);
    width: 8px;
    float: right;
    position: absolute;
    right: -9px;
    bottom: 4px;
}
.border-botom-text-optionsL:after {
    content: "";
    display: block;
    height: 8px;
    border-radius: 3px;
    background: linear-gradient(90deg,#fe5b01,#fe5b01 0,#fe5b01 100%,transparent 0);
    width: 60px;
    float: right;
    position: absolute;
    right: 0;
    bottom: -5px;
}
.mltexto_line {
    margin-left: 4%;
    display: inline-block;
}
@media screen and (min-width: 1100px) and (max-width: 1387px) {

}
@media screen and (min-width: 960px) {
    /*.border-botom-text-optionsR::after {
        top: calc((100% / 10.4) * 1);
        right: 86px; 
    }*/
}
@media screen and (min-width: 600px) {
    /*.border-botom-text-optionsR::after {
        top: calc((100% / 1.3) * 1);
        left: 56px;
        width: 62px;
    }
    .short .new-h2 {
        font-size: 3rem !important;
        padding-top: 15% !important;
        padding-left: 3% !important;
        text-align: left !important;
        z-index: 1;
    }
    .makeStyles-quarterParallaxMovil-220.options .option:nth-child(1) .option__content>img {
        left: 50%;
        right: 0px;
        bottom: -200px;
        height: 700px;
        margin-left: -480px;
        width: 100%;
    }*/
}
@media screen and (min-width: 1280px) { /* Pantallas grandes y superiores (lg) */ 

}
@media screen and (max-width: 1279px) and (min-width: 600px) { /* Pantallas pequeñas (sm) */

}
@media screen and (max-width: 599px) { /* Pantallas extra pequeñas (xs) */

}
@media screen and (min-width: 600px) { /* Estilos para el elemento <p> en pantallas pequeñas y superiores (sm) */

}

.new-h2 {
    font-size: 3rem !important;
    padding-top: 6% !important;
    padding-right: 13% !important;
    text-align: right !important;
}
.carousel .control-dots .dot {
    box-shadow: none !important;
    border: 1px solid #F1F1F1;
}

.inicia-tu-meta {
    padding: 3%;
    text-align: center;
}

.inicia-tu-meta button {
    border-radius: 27px;
    padding: 1% 2.2%;
    box-shadow: none;
    border: 3px solid;
}
.inicia-tu-meta .text-dark span {
    color: #000000;
}
.con-polen-logras .question h1 {
    padding: 3%;
    text-align: center;
    font-size: 2rem;
    background: #F1F1F1;
    margin-top: 0;
    margin-bottom: 0;
}

.con-polen-logras .question p {
    padding: 1%;
    text-align: center;
    background: #ffffff;
    margin-top: 0;
}

.con-polen-logras .question h1 span,
.que-meta-tienes-hoy .question h1 span {
    color: #fe5b02;
}

.que-meta-tienes-hoy .question h1 {
    text-align: center;
    font-size: 3rem;
    margin-top: 0;
    margin-bottom: 4vh;
    line-height: 55px;
}
.text-dark {
    color: #000000;
}
.text-white {
    color: #ffffff;
}
.mr-8px {
    margin-right: 8px;
}
.mb-4 {
    margin-bottom: 4% !important;
}
.p-13 {
    padding-left: 13% !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center;
}
.text-3 {
    font-size: 3vh !important;
}
.text-4 {
    font-size: 4vh !important;
}
.text-5 {
    font-size: 5vh !important;
}
.text-6 {
    font-size: 6vh !important;
}
.text-7 {
    font-size: 7vh !important;
}
.pb-0 {
    padding-bottom: 0;
}
.p-0 {
    padding: 0;
}
.p-6 {
    padding: 6vh;
}
.m-auto {
    margin: 0 auto;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.mt-6 {
    margin-top: 6vh;
}
.col-lg-12 {
    width: 100% !important;
}
.border-radius-40px {
    border-radius: 40px !important;
}
.col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-lg-9 {
    flex: 0 0 auto;
    width: 75%
}

.col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-lg-6 {
    flex: 0 0 auto;
    width: 50%
}

.col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-lg-3 {
    flex: 0 0 auto;
    width: 25%
}

.col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%
}

.meta-con-polen .question p {
    text-align: center;
    font-size: 3.4vh;
    padding: 0 15px;
}
.dicen-de-nosotros .question p {
    text-align: center;
    font-size: 3.8vh;
}
.dicen-de-nosotros {
    background: #F6F6F8;
}
