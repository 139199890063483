@mixin easeOut {
  transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
}

@mixin break900 {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin break600 {
  @media screen and (max-width: 600px) {
    @content;
  }
}

.strips {
  height: 400px;
  max-height: 400px;
  min-height: 400px;
  text-align: center;
  overflow: hidden;
  color: white;
  position: relative;
  max-width: 1024px;
  &__strip {
    will-change: width, left, z-index, height;
    position: absolute;
    width: 16.66%;
    height: 400px;
    max-height: 400px;
    min-height: 400px;
    overflow: hidden;
    cursor: pointer;
    @include easeOut;

    &:nth-child(1) {
      left: 0;
    }
    
    &:nth-child(2) {
      left: 16.66%;
    }
    
    &:nth-child(3) {
      left: 33.32%;
    }
    
    &:nth-child(4) {
      left: 49.98%;
    }
    
    &:nth-child(5) {
      left: 66.64%;
    }
    &:nth-child(6) {
        left: 83.3%;
        & .terms {
          color:black;
          font-size: 11px;
          & b {
            color:white
          }
        }
      }
    &:nth-child(1) {
      .strip__content {
        border-width: 0px;
        transform: translate3d(-100%, 0, 0);
        animation-name: strip1;
        animation-delay: 0.1s;
      }
    }
    
    &:nth-child(2) {
      .strip__content {
        border-width: 0px;
        transform: translate3d(0, 100%, 0);
        animation-name: strip1;
        animation-delay: 0.2s;
      }
    }
    
    &:nth-child(3) {
      .strip__content {
        border-width: 0px;
        transform: translate3d(0, -100%, 0);
        animation-name: strip1;
        animation-delay: 0.3s;
      }
    }
    
    &:nth-child(4) {
      .strip__content {
        border-width: 0px;
        transform: translate3d(0, 100%, 0);
        animation-name: strip1;
        animation-delay: 0.4s;
      }
    }
    
    &:nth-child(5) {
      .strip__content {
        border-width: 0px;
        transform: translate3d(100%, 0, 0);
        animation-name: strip1;
        animation-delay: 0.5s;
      }
    }

    &:nth-child(6) {
        .strip__content {
        border-width: 0px;
        transform: translate3d(100%, 0, 0);
          animation-name: strip1;
          animation-delay: 0.5s;
        }
      }
    
    @include break600 {
      min-height: 20vh;
      &:nth-child(1) {
        top: 0;
        left: 0;
        width: 100%;
      }

      &:nth-child(2) {
        top: 16.66vh;
        left: 0;
        width: 100%;
      }

      &:nth-child(3) {
        top: 33.32vh;
        left: 0;
        width: 100%;
      }

      &:nth-child(4) {
        top: 49.98vh;
        left: 0;
        width: 100%;
      }

      &:nth-child(5) {
        top:  66.64vh;
        left: 0;
        width: 100%;
      }

      &:nth-child(6) {
        top: 83.3vh;
        left: 0;
        width: 100%;
      }
    }
  }
  
  .strip__content {
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-fill-mode: both;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-decoration: none;

    & .buttonStart {
        // position: "absolute",
        // marginBottom: "30px",
        // marginLeft: "30px",
        display: flex;
        // float: 'left';
        width: 241px;
        height: 50px;
        font-weight: bold;
        align-content: center;
        align-items: center;
        padding:0px;
        & img {
            margin-right: 15px
        }
        & p{
          padding:0px;
          font-size: 14px;
        }
    }

    &:hover {
      &:before {
        transform: skew(-30deg) scale(3) translate(0, 0);
        opacity: 0.1;
      }
    }

    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      opacity: 0.05;
      transform-origin: center center;
      transform: skew(-30deg) scaleY(1) translate(0, 0);
      @include easeOut;
    }
  }

  .strips__icon{
    height: 30px;
    @include break600{
      height: 40px;
    }
    @include break600{
      height: 30px;
    }
  }
  
  .strip__inner-text {
    will-change: transform, opacity;
    position: absolute;
    justify-items: flex-start !important;
    align-items: flex-end !important;
    z-index: 5;
    left: 50%;
    top:50%;
    height: 400px;
    max-height: 400px;
    min-height: 400px;
    width: 100%;
    transform: translate(-50%,-50%) scale(0.5);
    opacity: 0;
    @include easeOut;
  }
  
  &__strip--expanded {
    width: 100%;
    left: 0 !important;
    z-index: 3;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: default;
    
    @include break600 {
      min-height: 50vh;
    }
    
    .strip__content {
      &:hover {
        &:before {
          transform: skew(-30deg) scale(1) translate(0, 0);
          opacity: 0.05;
        }
      }
    }
    
    .strip__title {
      opacity: 0;
    }
    
    .strip__inner-text {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.strip__title {
  display: block;
  margin: 0;
  position: relative;
  z-index: 2;
  width: 100%;
  font-size: 20px;
  color: white;
  @include easeOut;
  
  @include break600 {
    font-size: 28px;
  }
}

.strip__close {
  width: 30px;
  height: 30px;
  opacity: 0;
  z-index: 10;
  float: right;
  @include easeOut;
  z-index: 200;
  cursor: pointer;
  transition-delay: 0.5s;
  &--show {
    opacity: 1;
  }
}

@keyframes strip1 {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.strips_contain{
  padding: 20px;
  & .button-left{
    position: absolute;
    bottom: 30px;
    left: 16px;
    width: 241px;
    height: 60px;
    font-weight: bold;
    align-content: center;
    align-items: center;
  }
}

.strips_containt_image{
  height: 100%;
  position: absolute;
  z-index: -1;
}

.divRow{
  flex-direction: row;
}

.formColumn{

  display: flex;
  flex-direction: column;
  width: 250px;
  float: right;
  margin-right: 30px;
}
