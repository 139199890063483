
/* what is polen */
/* what is polen */
/* what is polen */

.positionAbsolute{
    position: absolute;
}

.animationFirstGirl {
    animation: ease-in firstGirl 1000ms;
}

.textMoveToRight {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    animation: slideinRight 40s linear infinite;
}

.textMoveToRight2 {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    animation: slideinRight2 80s linear infinite;
}

.textMoveToLeft {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    animation: slideinLeft 40s linear infinite;
}

@keyframes firstGirl {
    0% {
        transform: translateY(80px);
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideinRight {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

@keyframes slideinRight2 {
    0% {
        transform: translate(100, 0);
    }
    100% {
        transform: translate(0%, 0);
    }
}

@keyframes slideinLeft {
    0% {
        transform: translate(-100%, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}


/* what is polen */
/* what is polen */
/* what is polen */



/* Home */
/* Home */
/* Home */
@keyframes animatCallToAction {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animatHelpDesktop {
    0%{
        opacity: 0;
        transform: translateY(30px);
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes startYourHistory {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
    }
}

@keyframes letterInComunityYouMakeIt {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
    }
}

@keyframes TextSelectTheGoal {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
    }
}

@keyframes BoxWithParallaxPollen {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
    }
}

@keyframes rightThenLeft {
    0%   {margin-right:100%;}
    50%  {margin-right:0;}
    100% {margin-right:100%;}
}

.marqueeToRight{
    animation: ease-in rightThenLeft 4s linear;
    margin-right: 10000px;
}


/* Home */
/* Home */
/* Home */



/* parallax */
/* parallax */
/* parallax */

.select {
    transition: 1s;
    animation: ease-in animationSelect 1s;
    background-color: rgba(254,91,2,1);
}

@keyframes animationSelect {
    0% {
        background-color: rgba(254,91,2,0.5)
    }
    50% {
        background-color: rgba(254,91,2,1)
    }
    100% {
        /* background-color: rgba(254,91,2,1) */
    }
}

.TextSelectWhite {
    transition: 1s;
    animation: ease-in animationTextSelectWhite 1s;
    color: white !important;
}

@keyframes animationTextSelectWhite {
    0% {
        color: white
    }
    100% {
        color: white
    }
}


.TextSelectBlack {
    transition: 1s;
    animation: ease-in animationTextSelectBlack 1s;
    color: black !important;
}

@keyframes animationTextSelectBlack {
    0% {
        color: black
    }
    100% {
        color: black
    }
}


.showCirculeWhite {
    opacity: 1;
    transition: 1s;
    animation: ease-in animationShowCirculeWhite 1s;
    background-color: white !important;
}

@keyframes animationShowCirculeWhite {
    0% {
        background-color: white
    }
    100% {
        background-color: white
    }
}

/* parallax */
/* parallax */
/* parallax */
